@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-black: 0 0 0;
    --color-white: 255 255 255;
    --color-red: 247 113 102;
    --color-blue: 42 89 255;

    --color-light: 244 244 246;

    --color-text-primary: 38 43 57;
    --color-text-secondary: 125 128 136;
    --color-text-on-dark: 38 43 57;

    --color-stroke-light: 212 215 221;
    --color-stroke-light-2: 227 228 232;

    --color-element-light: 199 199 209;
    --color-element-dark: 38 43 57;

    --color-blue-button-hover: 0 53 240;
    --color-blue-button-focus: 0 45 204;
  }

  html {
    font-family: 'Poppins', sans-serif;
  }

  html,
  #root {
    height: 100vh;
  }

  body {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}
